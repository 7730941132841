import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const HeroAuthenticationDesktop = () => {
  return (
    <StaticImage
      src="https://res.cloudinary.com/cryptr/image/upload/v1655980860/Cryptr%20www/Landing%20pages/Authentication/Optimized%20images/hero_authentication_screen_wp5qot.jpg"
      alt="Hero authentication screen"
      className="authentication"
      quality={50}
      formats={['auto', 'webp', 'avif']}
      placeholder="none"
    />
  )
}

export const HeroAuthenticationMobile = () => {
  return (
    <StaticImage
      src="https://res.cloudinary.com/cryptr/image/upload/v1655980860/Cryptr%20www/Landing%20pages/Authentication/Optimized%20images/hero_authentication_mobile_yz0m5f.jpg"
      alt="Hero authentication mobile"
      className="authentication"
      quality={50}
      formats={['auto', 'webp', 'avif']}
      placeholder="none"
    />
  )
}
