import React from 'react'
import {
  HeroIllustrationDesktop,
  HeroIllustrationOneDevice,
  HeroIllustrationPhone,
  HeroIllustrationWPattern,
  HeroIllustrationScreenAndMobile,
  Mobile,
  Screen,
} from './style'

export const HeroAuthenticationIllustration = ({ mobile, desktop }: any) => {
  return (
    <HeroIllustrationScreenAndMobile>
      <Mobile>{mobile}</Mobile>
      <Screen>{desktop}</Screen>
    </HeroIllustrationScreenAndMobile>
  )
}

export const HeroScimIllustration = ({ children }: any) => {
  return <HeroIllustrationScreenAndMobile>{children}</HeroIllustrationScreenAndMobile>
}

// export const HeroIllustrationDevices = ({ heroData }: any) => {
//   return (
//     <>
//       <HeroIllustrationDesktop>
//         <img
//           loading="lazy"
//           src={heroData.hero_illustration_02.url}
//           alt={heroData.hero_illustration_02.alt}
//         />
//       </HeroIllustrationDesktop>
//       <HeroIllustrationPhone>
//         <img
//           loading="lazy"
//           src={heroData.hero_illustration_01.url}
//           alt={heroData.hero_illustration_01.alt}
//         />
//       </HeroIllustrationPhone>
//     </>
//   )
// }

// export const HeroIllustrationWithPattern = ({ heroData }: any) => {
//   return (
//     <HeroIllustrationWPattern>
//       <img
//         loading="lazy"
//         className="pattern"
//         src={heroData.hero_illustration_01.url}
//         alt={heroData.hero_illustration_01.alt}
//       />
//       <img
//         loading="lazy"
//         className="phone"
//         src={heroData.hero_illustration_02.url}
//         alt={heroData.hero_illustration_02.alt}
//       />
//     </HeroIllustrationWPattern>
//   )
// }

// export const HeroIllustrationDevice = ({ heroData }: any) => {
//   return (
//     <HeroIllustrationOneDevice>
//       <img
//         loading="lazy"
//         src={heroData.hero_illustration_01.url}
//         alt={heroData.hero_illustration_01.alt}
//       />
//     </HeroIllustrationOneDevice>
//   )
// }
