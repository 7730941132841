import { RichText, RichTextBlock } from 'prismic-reactjs'
import React from 'react'
import tw from 'twin.macro'
import { ContentGrid, ContentImage, ContentText } from './style'

const Image = ({ image, shadow }: { shadow: boolean; image: any }) => {
  return <ContentImage css={[shadow ? tw`shadow-2xl` : tw`shadow-none`]}>{image}</ContentImage>
}

const Text = ({
  subhead,
  title,
  description,
  slug,
}: {
  subhead: string
  title: string
  description: RichTextBlock[]
  slug: string
}) => (
  <ContentText>
    <p className={`subhead ${slug}`}>{subhead}</p>
    <h3>{title}</h3>
    <div>{RichText.render(description)}</div>
  </ContentText>
)

const SectionH3 = ({ items, slug, shadow, pictures }: any) => (
  <>
    {items.map(
      (
        item: {
          subhead: string
          title: string
          description: any
        },
        index: number,
      ) => {
        return (
          <ContentGrid key={index}>
            {index % 2 === 0 ? (
              <>
                <Image image={pictures[index].img} shadow={shadow} />
                <Text
                  slug={slug}
                  subhead={item.subhead}
                  title={item.title}
                  description={item.description.raw}
                />
              </>
            ) : (
              <>
                <Text
                  slug={slug}
                  subhead={item.subhead}
                  title={item.title}
                  description={item.description.raw}
                />
                <Image image={pictures[index].img} shadow={shadow} />
              </>
            )}
          </ContentGrid>
        )
      },
    )}
  </>
)

export default SectionH3
