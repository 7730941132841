import * as React from 'react'

function SvgDashboardIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={43} height={39} viewBox="0 0 43 39" fill="none" {...props}>
      <path
        d="M38.687 27.321V4.405H4.312V27.32h34.375zm0-26.736a3.82 3.82 0 013.82 3.82V27.32a3.82 3.82 0 01-3.82 3.82H25.319v3.82h3.819v3.819H13.86v-3.82h3.82v-3.82H4.312a3.82 3.82 0 01-3.82-3.819V4.405c0-2.12 1.7-3.82 3.82-3.82h34.375zM8.13 8.225H25.32v9.548H8.13V8.224zm19.097 0h7.64v3.819h-7.64v-3.82zm7.64 5.728v9.549h-7.64v-9.549h7.64zM8.13 19.683h7.639v3.819H8.131v-3.82zm9.549 0h7.639v3.819h-7.64v-3.82z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgDashboardIcon
