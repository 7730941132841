import styled from '@emotion/styled'
import tw from 'twin.macro'

export const HeroIllustrationScreenAndMobile = styled.div`
  ${tw`
    w-1/2
    relative
    flex
    items-center
  `}
`
export const Screen = styled.div`
  ${tw`
    w-2/3
    z-10
    ml-16
    shadow-2xl
    rounded-lg
    hidden
    md:block
    overflow-hidden
  `}
`
export const Mobile = styled.div`
  ${tw`
    absolute
    w-3/6
    z-20
    right-0
    overflow-hidden
    shadow-2xl
    hidden
    md:block
  `}
  border-radius: 2.5rem;
`

export const HeroIllustrationDesktop = styled.div`
  ${tw`
    absolute
    w-5/12
    h-full
    right-0
    flex
    items-center
    overflow-visible
  `}
  img {
    box-shadow: inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%),
      0 30px 60px -30px rgb(0 0 0 / 30%);
    @media (min-width: 768px) {
      ${tw`
        transform
        translate-x-20
        scale-150
        right-0
        origin-left
      `}
    }
    @media (min-width: 1400px) {
      ${tw`
        right-0
        origin-left
        scale-125
        translate-x-40
      `}
    }
    ${tw`
      rounded-lg
      hidden
      md:block
    `}
  }
`

export const HeroIllustrationScreenSecurityAccount = styled.div`
  ${tw`
    absolute
    w-3/12
    h-full
    flex
    items-center
    overflow-visible
  `}
  img {
    @media (min-width: 768px) {
      ${tw`
        transform
        translate-x-20
        scale-125
        right-0
        origin-left
      `}
    }
    @media (min-width: 1400px) {
      ${tw`
        right-0
        origin-left
        translate-x-40
      `}
    }
    ${tw`
      rounded-lg
      shadow-lg
      hidden
      md:block
    `}
  }
`

export const HeroIllustrationPhone = styled.div`
  ${tw`
    absolute
    w-4/12
    h-full
    right-0
    flex
    items-center
    overflow-visible
  `}
  @media (min-width: 768px) {
    bottom: -30px;
  }
  @media (min-width: 1400px) {
    bottom: 30px;
  }
  img {
    box-shadow: inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%),
      0 30px 60px -30px rgb(0 0 0 / 30%);
    @media (min-width: 768px) {
      border-radius: 2.5rem;
      ${tw`
        transform
        -translate-x-6
        translate-y-16
        scale-75
        right-0
        origin-left
      `}
    }
    @media (min-width: 1400px) {
      border-radius: 4rem;
      ${tw`
        right-0
        -translate-x-8
        translate-y-24
        origin-left
        scale-50
      `}
    }
    ${tw`
      hidden
      md:block
    `}
  }
`

export const HeroIllustrationPhoneSecurityAccount = styled.div`
  ${tw`
    absolute
    w-5/12
    h-full
    right-0
    flex
    items-center
    overflow-visible
  `}
  }
  img {
    ${tw`
      rounded-lg
    `}
    @media (min-width: 768px) {
      ${tw`
        transform
        scale-50
        right-0
        origin-left
      `}
    }
    @media (min-width: 1400px) {
      ${tw`
        right-0
        origin-left
        scale-50
      `}
    }
    ${tw`
      hidden
      md:block
    `}
  }
`

export const HeroIllustrationWPattern = styled.div`
  ${tw`
    relative
    hidden
    md:block
  `}
  width: 567px;
  height: 654px;
  img {
    ${tw`
      absolute
      block
      inset-0
      w-full
      h-full
      object-cover
    `}
  }
  .phone {
    width: 277px;
    height: 554px;
    border-radius: 2.5rem;
    ${tw`
      mx-auto
      my-auto
      shadow-lg
    `}
  }
`

export const HeroIllustrationOneDevice = styled.div`
  ${tw`
    absolute
    w-1/2
    h-full
    right-0
    flex
    items-center
  `}
  @media (min-width: 768px) {
    ${tw`
      overflow-hidden
    `}
  }
  @media (min-width: 1400px) {
    ${tw`
      overflow-visible
    `}
  }
  img {
    @media (min-width: 768px) {
      ${tw`
        transform
        scale-150
        right-0
        origin-left
      `}
    }
    @media (min-width: 1400px) {
      ${tw`
        right-0
        origin-left
      `}
    }
    ${tw`
      rounded-lg
      shadow-lg
      hidden
      md:block
    `}
  }
`
