import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const GoogleAccountExperience = () => {
  return (
    <StaticImage
      src="https://res.cloudinary.com/cryptr/image/upload/v1655980424/Cryptr%20www/Landing%20pages/Authentication/Optimized%20images/google_account_experience_tjxbhb.jpg"
      alt="Google account experience"
      quality={50}
      imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
      formats={['auto', 'webp', 'avif']}
      placeholder="none"
      className="image-to-top authentication"
    />
  )
}

export const CryptrUsersAsAService = () => {
  return (
    <StaticImage
      src="https://res.cloudinary.com/cryptr/image/upload/v1655980424/Cryptr%20www/Landing%20pages/Authentication/Optimized%20images/cryptr_users_as_a_service_sedpjl.jpg"
      alt="Cryptr users as a service"
      quality={50}
      imgStyle={{ borderBottomRightRadius: '0.5rem', borderBottomLeftRadius: '0.5rem' }}
      formats={['auto', 'webp', 'avif']}
      placeholder="none"
      className="image-to-bottom authentication"
    />
  )
}
