import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const authenticationImages = {
  feature_row: [
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1655980219/Cryptr%20www/Landing%20pages/Authentication/Optimized%20images/user_account_access_bbdwee.jpg"
          alt="User account access"
          imgStyle={{ borderRadius: '0.5rem' }}
          quality={50}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
          className="feature"
        />
      ),
    },
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1655980219/Cryptr%20www/Landing%20pages/Authentication/Optimized%20images/connected_devices_zoom_txmonk.jpg"
          alt="Connected device"
          imgStyle={{ borderRadius: '0.5rem' }}
          quality={50}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
          className="feature"
        />
      ),
    },
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1655980219/Cryptr%20www/Landing%20pages/Authentication/Optimized%20images/contacts_zoom_jx6iwa.jpg"
          alt="Contacts"
          imgStyle={{ borderRadius: '0.5rem' }}
          quality={50}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
          className="feature"
        />
      ),
    },
  ],
}
