import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const sectionH3Images = {
  passwordless: [
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1656082442/Cryptr%20www/Landing%20pages/Authentication/Optimized%20images/onboard_quickly_new_users_tu2fav.jpg"
          alt="Onboard quickly new users"
          quality={50}
          width={1080}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
          className="feature"
        />
      ),
    },
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1656082442/Cryptr%20www/Landing%20pages/Authentication/Optimized%20images/simple_login_from_email_juxeii.jpg"
          alt="Simple login from email'"
          quality={50}
          width={1080}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
          className="feature"
        />
      ),
    },
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1656082442/Cryptr%20www/Landing%20pages/Authentication/Optimized%20images/long_authentication_session_aqcaca.jpg"
          alt="Long authentication session"
          quality={50}
          width={1080}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
          className="feature"
        />
      ),
    },
  ],
  widget: [
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1656082442/Cryptr%20www/Landing%20pages/Authentication/Optimized%20images/widget_duucea.jpg"
          alt="Widget"
          quality={50}
          width={1080}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
          className="feature"
        />
      ),
    },
  ],
}
