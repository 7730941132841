import * as React from 'react'

function SvgGoogleIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21.456 10.154c.122.659.19 1.348.19 2.067 0 5.624-3.765 9.623-9.45 9.623A9.84 9.84 0 012.354 12a9.841 9.841 0 019.844-9.844c2.658 0 4.879.978 6.582 2.566l-2.774 2.775V7.49C14.972 6.506 13.66 6 12.197 6c-3.248 0-5.888 2.745-5.888 5.994 0 3.248 2.64 5.998 5.888 5.998 2.947 0 4.953-1.685 5.364-4h-5.364v-3.838h9.26-.002z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgGoogleIcon
