import React from 'react'
import CallToActionSection from '../../components/call_to_action_section'
import FeatureRow from '../../components/features/feature_row'
import FourColsWithFeatures from '../../components/four_cols_features'
import Hero from '../../components/hero'
import { AuthenticationHeroBackground } from '../../components/hero/hero_background'
import { HeroAuthenticationIllustration } from '../../components/hero/hero_illustration'
import Layout from '../../components/layout'
import SectionH3 from '../../components/sections_h3'
import SectionWrapper from '../../components/sectionWrapper'
import SectionH2 from '../../components/section_h2'
import SectionH3Image from '../../components/section_h3_image'
import SectionLogosBase from '../../components/section_logos_base'
import SvgDashboardIcon from '../../components/svgs/icons/dashboard_icon'
import SvgGoogleIcon from '../../components/svgs/icons/google_icon'
import SvgPadlockCloseIcon from '../../components/svgs/icons/padlock_close_icon'
import SvgAuthenticationIcon from '../../components/svgs/icons/products/authentication_icon'
import { partnerLogos } from '../../cdn_images/logos'
import { sectionH3Images } from '../../components/images/authentication/section_h3'
import { authenticationImages } from '../../components/images/authentication/feature'
import {
  HeroAuthenticationDesktop,
  HeroAuthenticationMobile,
} from '../../components/images/authentication/hero'
import {
  CryptrUsersAsAService,
  GoogleAccountExperience,
} from '../../components/images/authentication/section_h3_image'

const featureIcons = [
  {
    feature01: <SvgPadlockCloseIcon color="#02AFD7" />,
  },
  {
    feature02: <SvgGoogleIcon color="#02AFD7" />,
  },
  {
    feature03: <SvgDashboardIcon color="#02AFD7" />,
  },
]

const AuthenticationPage = ({ pageContext: { data, localePath } }: any) => {
  let firstFeatureForH3Image = [data.body[4].items[0]]
  let secondFeatureForH3Image = [data.body[4].items[1]]

  return (
    <Layout
      helmetTitle={data.header_title.raw[0].text}
      helmetDescription={data.meta_description}
      helmetImage={data.meta_image.url}
      helmetUrl={`https://www.cryptr.co${localePath}/`}
    >
      <Hero
        background_shape={<AuthenticationHeroBackground />}
        icon={<SvgAuthenticationIcon />}
        category={data.category}
        call_to_action_color={data.slug}
        pageID={data.slug}
        title={data.header_title.raw}
        description={data.header_description.raw}
        value_propositions={data.value_proposition}
        call_to_action={data.call_to_action}
      >
        <HeroAuthenticationIllustration
          mobile={<HeroAuthenticationMobile />}
          desktop={<HeroAuthenticationDesktop />}
        />
      </Hero>
      <SectionWrapper color="dark-blue">
        <SectionLogosBase items={partnerLogos} />
      </SectionWrapper>
      <SectionWrapper disabledPadding="padding-auto">
        <SectionH2
          slug={data.slug}
          title={data.body[1].section_H2_primary.title}
          description={data.body[1].section_H2_primary.description.raw}
          textAlignment="text-left"
          subhead={null}
          gridColumns={true}
        />
        <SectionH3
          items={data.body[2].items}
          slug={data.slug}
          shadow={false}
          pictures={sectionH3Images.passwordless}
        />
      </SectionWrapper>
      <SectionWrapper color="light-blue" disabledPadding="bottom">
        <SectionH2
          slug={data.slug}
          title={data.body[3].section_H2_primary.title}
          description={data.body[3].section_H2_primary.description.raw}
          textAlignment="text-center"
          subhead={null}
          gridColumns={false}
        >
          <SectionH3Image
            slug={data.slug}
            features={firstFeatureForH3Image}
            image={<GoogleAccountExperience />}
            contentPosition="to-top"
            featuresOrder="to-left"
            imageOrder="to-right"
            icons={null}
          />
        </SectionH2>
      </SectionWrapper>
      <SectionWrapper color="dark-blue" disabledPadding="top">
        <SectionH3Image
          slug={data.slug}
          features={secondFeatureForH3Image}
          image={<CryptrUsersAsAService />}
          contentPosition="to-bottom"
          featuresOrder="to-left"
          imageOrder="to-right"
          icons={null}
        />
        <SectionH2
          slug={data.slug}
          title={data.body[7].section_H2_primary.title}
          description={data.body[7].section_H2_primary.description.raw}
          textAlignment="text-center"
          subhead={null}
          color="on-dark-background"
          gridColumns={false}
        />
      </SectionWrapper>
      <SectionWrapper disabledPadding="padding-auto">
        <SectionH2
          slug={data.slug}
          title={data.body[8].section_H2_primary.title}
          description={data.body[8].section_H2_primary.description.raw}
          textAlignment="text-left"
          subhead={null}
          gridColumns={true}
        >
          <FeatureRow
            slug={data.slug}
            features={data.body[9].items}
            images={authenticationImages.feature_row}
          />
          <SectionH3
            items={data.body[10].items}
            slug={data.slug}
            shadow={false}
            pictures={sectionH3Images.widget}
          />
        </SectionH2>
      </SectionWrapper>
      <SectionWrapper color="dark-blue" disabledPadding="padding-auto">
        <SectionH2
          slug={data.slug}
          title={data.body[11].section_H2_primary.title}
          description={data.body[11].section_H2_primary.description.raw}
          textAlignment="text-left"
          subhead={null}
          gridColumns={true}
          color="on-dark-background"
        >
          <FourColsWithFeatures
            featureIcons={featureIcons}
            feature={data.body[12].items}
            slug={data.slug}
          />
        </SectionH2>
      </SectionWrapper>
      <CallToActionSection callToActionSectionData={data.body[13]} />
    </Layout>
  )
}

export default AuthenticationPage
